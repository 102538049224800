import { Outlet } from 'react-router-dom';

const RootLayout = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between bg-gradient-to-r from-blue-600 to-purple-600">
      {/* Header or Navigation */}
      <header className="p-6 bg-opacity-20 bg-black text-white">
        <h1 className="text-3xl font-bold">Crysto</h1>
      </header>

      {/* Main Content - Outlet will render the child components */}
      <main className="flex-grow flex items-center justify-center">
        <Outlet />
      </main>

      {/* Footer */}
      <footer className="p-4 bg-opacity-20 bg-black text-gray-400 text-center">
        © {new Date().getFullYear()} Crysto. All rights reserved.
      </footer>
    </div>
  );
};

export default RootLayout;
