const ComingSoon = () => {
  return (
    <div className="text-center p-6 max-w-md">
      <h2 className="text-4xl sm:text-6xl font-bold text-white mb-4">
        Coming Soon
      </h2>
      <p className="text-lg text-gray-200 mb-6">
        We are building something amazing. Stay tuned for updates!
      </p>
      {/*<form className="flex flex-col sm:flex-row justify-center items-center">*/}
      {/*  <input*/}
      {/*    type="email"*/}
      {/*    placeholder="Enter your email"*/}
      {/*    className="px-4 py-2 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-purple-400 w-full sm:w-auto sm:flex-grow mb-4 sm:mb-0 sm:mr-4"*/}
      {/*  />*/}
      {/*  <button className="bg-white text-purple-600 font-bold py-2 px-6 rounded-lg hover:bg-purple-100 transition duration-300">*/}
      {/*    Notify Me*/}
      {/*  </button>*/}
      {/*</form>*/}
    </div>
  );
};

export default ComingSoon;
